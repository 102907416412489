<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-5">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="https://kmcorporate.com/wp-content/uploads/2021/06/MIC-25-CON-PENNELLATE-1024x696.png"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-7">
                <div class="mt-4">
                  <h1
                    style="color: black; font-weight: 800"
                    class="font-size-20 mb-3"
                  >
                    FEATURES
                  </h1>
                  <p style="font-size: 16px; color: black; font-weight: 400">
                    Mic 25 is an electro-pneumatic bench equipment, specifically
                    designed for the production of small and medium quantities
                    of harnesses with IDC connectors STOCKO ECOTRONIC and PRO
                    series. Termination operations are performed in rapid
                    succession. The device is prepared manually by loading the
                    loose connectors on a special guide. The type of connector
                    and the number of ways are quickly set on a simple operator
                    panel. The termination command and therefore the advancement
                    of the connector for the termination of the next way is
                    activated by a pedal allowing the operator to have both
                    hands free. The device can terminate both the ECOTRONIC and
                    PRO connector by simply changing the termination punch. The
                    modular structure and the use of maintenance-free and
                    wear-free components guarantee the extreme reliability of
                    the equipment.
                  </p>
                </div>
                <br />
                <h1
                  style="color: black; font-weight: 800"
                  class="font-size-20 mb-3"
                >
                  TECHNICAL DATA
                </h1>
                <br />
                <h3 style="color: black; font-weight: 800">Guide</h3>

                <p style="font-size: 16px; color: black; font-weight: 400">
                  Pneumatics
                </p>

                <br />
                <h3 style="color: black; font-weight: 800">Power supply</h3>
                <p style="font-size: 16px; color: black; font-weight: 400">
                  230 V AC 50/60 Hz
                </p>

                <br />
                <h3 style="color: black; font-weight: 800">
                  Operating pressure
                </h3>
                <p style="font-size: 16px; color: black; font-weight: 400">
                  6 bar
                </p>

                <br />
                <h3 style="color: black; font-weight: 800">Sound level</h3>
                <p style="font-size: 16px; color: black; font-weight: 400">
                  60 dB
                </p>

                <br />
                <h3 style="color: black; font-weight: 800">Dimensions</h3>
                <p style="font-size: 16px; color: black; font-weight: 400">
                  L 1300 mm x P 1800 mm x H 2450 mm (with open guards)
                </p>

                <br />
                <h3 style="color: black; font-weight: 800">Weight</h3>
                <p style="font-size: 16px; color: black; font-weight: 400">
                  1000 kg
                </p>

                <br />
                <h3 style="color: black; font-weight: 800">CE certification</h3>

                <br />
                <p style="font-size: 14px; color: black; font-weight: 400">
                  Data and measurements are indicative and subject to change
                  without notice. Some particular types of cables may not be
                  machined even though they fall within the range of sections
                  indicated. KM Corporate will still be happy to carry out tests
                  and supply wire-worked samples
                </p>
              </div>
            </div>
            <!-- end row -->
            <br>
            <br>
            <br>
            <div class="row">
              <h1
                style="color: black; font-weight: 800;margin-left:40px;"
                class="font-size-20 mb-3"
              >
                Gallery
              </h1>
         </div>
            <!-- end row -->
            <div class="row">
            <div style="margin-left:200px;">
              <vue-picture-swipe :items="items"></vue-picture-swipe>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
    data(){
           return {
        items: [{
          src: 'https://kmcorporate.com/wp-content/uploads/2021/07/MIC-25-re.1-210714-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/MIC-25-re.1-210714-150x150.jpg',
          w: 1200,
          h: 900,
          alt: 'some numbers on a grey background' // optional alt attribute for thumbnail image
        },
        {
          src: 'https://kmcorporate.com/wp-content/uploads/2021/06/KM-012-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/06/KM-012-150x150.jpg',
          w: 1200,
          h: 900
        },
        {
          src: 'https://kmcorporate.com/wp-content/uploads/2021/06/KM-028-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/06/KM-028-150x150.jpg',
          w: 1200,
          h: 900
        },
        {
          src: 'https://kmcorporate.com/wp-content/uploads/2021/06/KM-043-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/06/KM-043-150x150.jpg',
          w: 1200,
          h: 900
        },
       
      ]};

  }
};
</script>